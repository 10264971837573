import { z } from 'zod';
import { CollaboratorSchema } from './Collaborator';
import type { UserId, WorkspaceId } from './Opaques';
import { ResourceIdSchema, SongIdSchema, WorkspaceIdSchema } from './Opaques';
import { PermissionSchema } from './Permission';
import { transformToFalse, transformToNull } from './zod-utils';

export const SongMetadataSchema = z
  .object({
    bit_rate: z.string().optional().transform(transformToNull),
    channel_layout: z.string().optional().transform(transformToNull),
    channels: z.string().optional().transform(transformToNull),
    codec_long_name: z.string().optional().transform(transformToNull),
    codec_name: z.string().optional().transform(transformToNull),
    duration: z.coerce.number().optional().transform(transformToNull),
    format_long_name: z.string().optional().transform(transformToNull),
    format_name: z.string().optional().transform(transformToNull),
    metadata_comments: z.string().nullish().transform(transformToNull),
    metadata_contains_music: z.string().nullish().transform(transformToNull),
    metadata_copyright_date: z.string().nullish().transform(transformToNull),
    metadata_description: z.string().nullish().transform(transformToNull),
    metadata_distributor: z.string().optional().transform(transformToNull),
    metadata_explicit: z.string().optional().transform(transformToNull),
    metadata_grouping: z.string().nullish().transform(transformToNull),
    metadata_isrc: z.string().optional().transform(transformToNull),
    metadata_iswc: z.string().optional().transform(transformToNull),
    metadata_key: z.string().nullish().transform(transformToNull),
    metadata_language: z.string().optional().transform(transformToNull),
    metadata_license: z.string().optional().transform(transformToNull),
    metadata_master_owner: z.string().optional().transform(transformToNull),
    metadata_order: z.string().nullish().transform(transformToNull),
    metadata_pline: z.string().optional().transform(transformToNull),
    metadata_publisher: z.string().optional().transform(transformToNull),
    metadata_record_label: z.string().nullish().transform(transformToNull),
    metadata_release_date: z.string().optional().transform(transformToNull),
    metadata_release_title: z.string().nullish().transform(transformToNull),
    metadata_upc_barcode: z.string().optional().transform(transformToNull),
    sample_fmt: z.string().optional().transform(transformToNull),
    sample_rate: z.string().optional().transform(transformToNull),
    size: z.string().optional().transform(transformToNull),
  })
  .transform((prev) => ({
    bitRate: prev.bit_rate,
    channelLayout: prev.channel_layout,
    channels: prev.channels,
    codecLongName: prev.codec_long_name,
    codecName: prev.codec_name,
    duration: prev.duration,
    formatLongName: prev.format_long_name,
    formatName: prev.format_name,
    metadataComments: prev.metadata_comments,
    metadataContains_music: prev.metadata_contains_music,
    metadataCopyrightDate: prev.metadata_copyright_date,
    metadataDescription: prev.metadata_description,
    metadataDistributor: prev.metadata_distributor,
    metadataExplicit: prev.metadata_explicit,
    metadataGrouping: prev.metadata_grouping,
    metadataIsrc: prev.metadata_isrc,
    metadataIswc: prev.metadata_iswc,
    metadataKey: prev.metadata_key,
    metadataLanguage: prev.metadata_language,
    metadataLicense: prev.metadata_license,
    metadataMasterOwner: prev.metadata_master_owner,
    metadataOrder: prev.metadata_order,
    metadataPline: prev.metadata_pline,
    metadataPublisher: prev.metadata_publisher,
    metadataRecordLabel: prev.metadata_record_label,
    metadataReleaseDate: prev.metadata_release_date,
    metadataReleaseTitle: prev.metadata_release_title,
    metadataUpcBarcode: prev.metadata_upc_barcode,
    sampleFmt: prev.sample_fmt,
    sampleRate: prev.sample_rate,
    size: prev.size,
  }));

export const BackendSongSchema = z
  .object({
    audio_id: SongIdSchema,
    authenticated_orig_path: z.string(),
    comment_count: z.number().nullish().transform(transformToNull),
    cover_image_orig_path: z.string().nullish().transform(transformToNull),
    cover_image_path: z.string().nullish().transform(transformToNull),
    created_at: z.string().transform((date) => new Date(date)),
    deleted_at: z
      .string()
      .transform((date) => new Date(date))
      .nullish()
      .transform(transformToNull),
    duration: z.number().nullish().transform(transformToNull),
    encrypted: z.number().nullish().transform(transformToNull),
    favorited_at: z.string().nullish().transform(transformToNull),
    is_favorite: z.boolean(),
    key_path: z.string(),
    m3u8_path: z.string(),
    metadata: SongMetadataSchema,
    // metadata: SongMetadataSchema.optional().transform(transformToNull),
    metadata_album: z.string().nullish().transform(transformToNull),
    metadata_album_artist: z.string().nullish().transform(transformToNull),
    metadata_artist: z.string().nullish().transform(transformToNull),
    metadata_bitrate: z.number().nullish().transform(transformToNull),
    metadata_bpm: z.number().nullish().transform(transformToNull),
    metadata_channels: z.number().nullish().transform(transformToNull),
    metadata_codec_profile: z.string().nullish().transform(transformToNull),
    metadata_composer: z.string().nullish().transform(transformToNull),
    metadata_copyright: z.string().nullish().transform(transformToNull),
    metadata_format: z.string().nullish().transform(transformToNull),
    metadata_genre: z.string().nullish().transform(transformToNull),
    metadata_rating: z.number().nullish().transform(transformToNull),
    metadata_sample_rate: z.number().nullish().transform(transformToNull),
    metadata_title: z.string().nullish().transform(transformToNull),
    metadata_track_count: z.number().nullish().transform(transformToNull),
    metadata_track_number: z.number().nullish().transform(transformToNull),
    metadata_year: z.number().nullish().transform(transformToNull),
    orig_path: z.string().nullish().transform(transformToNull),
    original_filename: z.string().nullish().transform(transformToNull),
    path: z.string().optional().transform(transformToNull),
    permission_list: z.array(PermissionSchema),
    play_count: z.number().nullish().transform(transformToNull),
    public_link_url: z.string().nullish().transform(transformToNull),
    resource_id: ResourceIdSchema,
    share_id: z.string().nullish().transform(transformToNull),
    size: z.number().nullish().transform(transformToNull),
    title: z.string(),
    transcoding: z.number().transform(Boolean),
    uploaded_at: z
      .string()
      .transform((date) => new Date(date))
      .nullish(),
    uploader: CollaboratorSchema,
    uploading: z.boolean().nullish().transform(transformToFalse),
    version_id: z.number(),
    web_playback_path: z.string(),
  })
  .transform((prev) => ({
    audioId: prev.audio_id,
    authenticatedOrigPath: prev.authenticated_orig_path,
    commentCount: prev.comment_count,
    coverImageOrigPath: prev.cover_image_orig_path,
    coverImagePath: prev.cover_image_path,
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    docType: 'song' as const,
    duration: prev.duration,
    encrypted: prev.encrypted,
    favoritedAt: prev.favorited_at,
    isFavorite: prev.is_favorite,
    keyPath: prev.key_path,
    m3u8Path: prev.m3u8_path,
    metadata: prev.metadata,
    metadataAlbum: prev.metadata_album,
    metadataAlbumArtist: prev.metadata_album_artist,
    metadataArtist: prev.metadata_artist,
    metadataBitrate: prev.metadata_bitrate,
    metadataBpm: prev.metadata_bpm,
    metadataChannels: prev.metadata_channels,
    metadataCodecProfile: prev.metadata_codec_profile,
    metadataComposer: prev.metadata_composer,
    metadataCopyright: prev.metadata_copyright,
    metadataFormat: prev.metadata_format,
    metadataGenre: prev.metadata_genre,
    metadataRating: prev.metadata_rating,
    metadataSampleRate: prev.metadata_sample_rate,
    metadataTitle: prev.metadata_title,
    metadataTrackCount: prev.metadata_track_count,
    metadataTrackNumber: prev.metadata_track_number,
    metadataYear: prev.metadata_year,
    origPath: prev.orig_path,
    originalFilename: prev.original_filename,
    path: prev.path,
    permissionList: prev.permission_list,
    playCount: prev.play_count,
    resourceId: prev.resource_id,
    size: prev.size,
    title: prev.title,
    transcoding: prev.transcoding,
    uploadedAt: prev.uploaded_at,
    uploader: prev.uploader,
    uploading: prev.uploading,
    versionId: prev.version_id,
    webPlaybackPath: prev.web_playback_path,
  }));

export const SongListResponseSchema = z.object({
  records: z.array(BackendSongSchema),
  size: z.number(),
});

export type Song = z.infer<typeof BackendSongSchema> & {
  workspaceId: WorkspaceId;
};

export function ParseSong(value: unknown, workspaceId: WorkspaceId): Song {
  return { ...BackendSongSchema.parse(value), workspaceId };
}

export interface Metadata {
  bitRate: string | null;
  channelLayout: string | null;
  channels: string | null;
  codecLongName: string | null;
  codecName: string | null;
  duration: string | null;
  formatLongName: string | null;
  formatName: string | null;
  sampleFmt: string | null;
  sampleRate: string | null;
  size: string | null;
  bitsPerRawSam: string | null;
}

export const defaultSong: Song = {
  resourceId: ResourceIdSchema.parse(''),
  audioId: SongIdSchema.parse('06b355e9-7'),
  workspaceId: WorkspaceIdSchema.parse('w1'),
  coverImagePath: null,
  createdAt: new Date('2023-03-09T08:20:56.887Z'),
  deletedAt: null,
  duration: 37.62,
  docType: 'song',
  // isFavorite: false,
  metadata: {
    bitRate: '320000',
    channelLayout: null,
    channels: 'stereo',
    codecLongName: '2',
    codecName: 'MP3 (MPEG audio layer 3)',
    duration: 0,
    formatLongName: '37.616325',
    formatName: 'MP2/3 (MPEG audio layer 2/3)',
    metadataComments: null,
    metadataContains_music: null,
    metadataCopyrightDate: null,
    metadataDescription: null,
    metadataDistributor: null,
    metadataExplicit: null,
    metadataGrouping: null,
    metadataIsrc: null,
    metadataIswc: null,
    metadataKey: null,
    metadataLanguage: null,
    metadataLicense: null,
    metadataMasterOwner: null,
    metadataOrder: null,
    metadataPline: null,
    metadataPublisher: null,
    metadataRecordLabel: null,
    metadataReleaseDate: null,
    metadataReleaseTitle: null,
    metadataUpcBarcode: null,
    sampleFmt: 'mp3',
    sampleRate: 'fltp',
    size: '44100',
  },
  metadataAlbum: 'Royalty Free',
  metadataAlbumArtist: null,
  metadataArtist: 'Kevin MacLeod',
  metadataBpm: null,
  metadataCodecProfile: 'CBR',
  metadataComposer: 'Kevin MacLeod',
  metadataCopyright: null,
  metadataFormat: 'MPEG',
  metadataGenre: 'TV & Film',
  metadataTrackNumber: null,
  metadataYear: 2010,
  metadataRating: null,
  metadataTrackCount: null,
  origPath:
    'https://dvr722unfp578.cloudfront.net/audio/orig/e5358b0f-97b9-4747-84e0-81188cd6ad3f.mp3',
  originalFilename: 'There It Is 39.mp3',
  path: 'https://trn-ctrl-api-staging-ws.herokuapp.com/audio/06b355e9-7/m3u8/48875',
  size: 1506892,
  title: 'There It Is 39.mp3',
  transcoding: false,
  uploadedAt: new Date('2023-03-09T08:21:00.000Z'),
  uploading: false,
  uploader: {
    artistName: '',
    avatarUrl: null,
    createdAt: new Date(),
    email: '',
    location: null,
    name: '',
    occupation: null,
    userId: '1' as UserId,
  },
  authenticatedOrigPath: '',
  coverImageOrigPath: '',
  isFavorite: false,
  keyPath: '',
  m3u8Path: '',
  metadataBitrate: 0,
  metadataChannels: 0,
  metadataSampleRate: 0,
  metadataTitle: null,
  versionId: 0,
  webPlaybackPath: '',
  commentCount: 0,
  encrypted: null,
  favoritedAt: null,
  playCount: 0,
  permissionList: [],
};
