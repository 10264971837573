import { pipe } from 'fp-ts/lib/function';
import Hls from 'hls.js';
import { noop } from 'lodash';
import type React from 'react';
import {
  type ActorRefFromLogic,
  type CallbackActorLogic,
  type DoneActorEvent,
  type SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  fromCallback,
  fromPromise,
  setup,
  stopChild,
} from 'xstate';
import { DownloadState } from '../../common/DownloadState';
import type { OfflineSong } from '../../common/OfflineSong';
import type { Song } from '../../common/Song';
import { requestAnimationFrameInterval } from '../utils/requestAnimationFrameInterval';

const VOLUME_FADE_DURATION = 30;
const VOLUME_MIN = 0.0001;

/**
 * {@link https://stackoverflow.com/a/36898221/9130688}
 */
function isPlayingAudioElement(audioEl: HTMLAudioElement): boolean {
  return (
    !audioEl.paused &&
    !audioEl.ended &&
    audioEl.readyState > audioEl.HAVE_CURRENT_DATA &&
    audioEl.currentTime > 0
  );
}

function onAudioElError(e: ErrorEvent) {
  console.error(e);
}

function destroyHls(hls: Hls) {
  try {
    hls.stopLoad();
    hls.detachMedia();
    hls.destroy();
  } catch (e) {
    console.error(e);
  }
}

function resetVolumeMutable(
  gainNodeRef: React.RefObject<GainNode | undefined>,
  audioContextRef: React.RefObject<AudioContext | undefined>,
  playerVolume: number
) {
  const gainNode = gainNodeRef.current;
  const context = audioContextRef.current;
  if (gainNode && context) {
    gainNode.gain.setValueAtTime(VOLUME_MIN, context.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      Math.max(playerVolume, VOLUME_MIN),
      context.currentTime + VOLUME_FADE_DURATION / 1000
    );
  }
}
async function playMutable({
  audioRef,
  gainNodeRef,
  audioContextRef,
  playerVolumeRef,
}: PlayerContext) {
  if (audioContextRef!.current === undefined) {
    // note: creating an audio context has to be done after interacting with the page, so it is ideal to do it here, assigning the audio context, gain node and source node to the refs is to be able to remove the pop when muting.

    const context = new AudioContext();
    audioContextRef!.current = context;
    const sourceNode = context.createMediaElementSource(audioRef!.current!);
    const gainNode = context.createGain();
    gainNodeRef!.current = gainNode;
    sourceNode.connect(gainNode);
    gainNode.connect(context.destination);
  }

  if (audioRef && gainNodeRef && audioContextRef && playerVolumeRef) {
    try {
      await audioRef.current?.play();
      resetVolumeMutable(gainNodeRef, audioContextRef, playerVolumeRef.current);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export type MountData = {
  playingSong: Pick<Song, 'path'> & Partial<Pick<Song, 'workspaceId'>>;
  offlineSong: OfflineSong | undefined;
  progress: number;
  autoPlay: boolean;
  authToken?: string;
};

export type PlayerContext = {
  audioContextRef?: React.RefObject<AudioContext | undefined>;
  audioRef?: React.RefObject<HTMLAudioElement | undefined>;
  buffer?: MountData;
  fallback?: MountData;
  gainNodeRef?: React.RefObject<GainNode | undefined>;
  hlsRef?: ActorRefFromLogic<CallbackActorLogic<PlayerEvent>>;
  maxRetries?: number;
  onDestroy?: () => void;
  onError?: () => void;
  onHealthy?: () => void;
  onProgress?: (value: number) => void;
  pausingRef?: ActorRefFromLogic<CallbackActorLogic<PlayerEvent>>;
  playerVolumeRef?: React.RefObject<number>;
  playingRef?: ActorRefFromLogic<CallbackActorLogic<PlayerEvent>>;
  timeUpdateRef?: ActorRefFromLogic<CallbackActorLogic<PlayerEvent>>;
};

type MountingFromStreamData =
  | {
      goTo: 'unmounted';
    }
  | {
      goTo: 'tryToPlay';
    }
  | {
      goTo: 'paused';
    };

type MountedEvent = {
  type: 'MOUNTED';
  payload: MountData;
};
type SoughtEvent = {
  type: 'SOUGHT';
  payload: { time: number; cb: (time: number) => void };
};
type ForwardedEvent = {
  type: 'FORWARDED';
  payload: { add: number; cb: (time: number) => void };
};
type PausedEvent = { type: 'PAUSED' };
type PlaybackToggledEvent = { type: 'PLAYBACK_TOGGLED' };

type PlayerEvent =
  | {
      type: 'INIT';
      payload: Required<
        Pick<
          PlayerContext,
          | 'audioContextRef'
          | 'audioRef'
          | 'gainNodeRef'
          | 'maxRetries'
          | 'onError'
          | 'onHealthy'
          | 'onProgress'
          | 'playerVolumeRef'
        >
      >;
    }
  | MountedEvent
  | {
      type: 'INTERNAL_STREAM_MOUNTED';
      payload: MountingFromStreamData;
    }
  | { type: 'PLAYED' }
  | SoughtEvent
  | ForwardedEvent
  | PlaybackToggledEvent
  | PausedEvent
  | {
      type: 'INTERNAL_PAUSE_DONE';
      payload:
        | MountedEvent
        | SoughtEvent
        | ForwardedEvent
        | PausedEvent
        | PlaybackToggledEvent;
    }
  | { type: 'ENDED' }
  | { type: 'UNMOUNTED' }
  | { type: 'ERRORED' }
  | { type: 'HEALTHY' }
  | { type: 'DESTROY' }
  | DoneActorEvent<MountingData, 'mount'>;

export type PlayerSelectorState = SnapshotFrom<typeof playerMachine>;

type MountingData =
  | {
      goTo: 'unmounted';
    }
  | {
      goTo: 'tryToPlay';
      fallback: MountData;
      onDestroy: () => void;
    }
  | {
      goTo: 'paused';
      fallback: MountData;
      onDestroy: () => void;
    }
  | {
      goTo: 'mountFromStream';
      fallback: MountData;
    };

export const playerMachine = setup({
  types: {
    context: {} as PlayerContext,
    events: {} as PlayerEvent,
  },
  guards: {
    shouldGoToPlay: ({ event }) => {
      assertEvent(event, [
        'xstate.done.actor.mount',
        'INTERNAL_STREAM_MOUNTED',
      ]);

      switch (event.type) {
        case 'xstate.done.actor.mount':
          return event.output.goTo === 'tryToPlay';
        case 'INTERNAL_STREAM_MOUNTED':
          return event.payload.goTo === 'tryToPlay';
        default:
          return false;
      }
    },
    shouldGoToPaused: ({ event }) => {
      assertEvent(event, [
        'xstate.done.actor.mount',
        'INTERNAL_STREAM_MOUNTED',
      ]);
      switch (event.type) {
        case 'xstate.done.actor.mount':
          return event.output.goTo === 'paused';
        case 'INTERNAL_STREAM_MOUNTED':
          return event.payload.goTo === 'paused';
        default:
          return false;
      }
    },
    isSought: ({ event }) => {
      assertEvent(event, 'INTERNAL_PAUSE_DONE');
      return event.payload.type === 'SOUGHT';
    },
    isForwarded: ({ event }) => {
      assertEvent(event, 'INTERNAL_PAUSE_DONE');
      return event.payload.type === 'FORWARDED';
    },
    isMounted: ({ event }) => {
      assertEvent(event, 'INTERNAL_PAUSE_DONE');
      return event.payload.type === 'MOUNTED';
    },
    isPaused: ({ event }) => {
      assertEvent(event, 'INTERNAL_PAUSE_DONE');
      return true;
    },
    hasBuffer: ({ context }) => Boolean(context.buffer),
    hasFallback: ({ context }) => Boolean(context.fallback),
    shouldGoToMountFromStream: ({ event }) => {
      assertEvent(event, ['xstate.done.actor.mount']);
      return event.output.goTo === 'mountFromStream';
    },
  },
  actions: {
    destroy: enqueueActions(({ context, enqueue }) => {
      // TODO: this should be an intermediate state, like pausing, because it seems destrying is not being waited before entering mounting

      context.onProgress?.(0);
      enqueue.assign({
        onDestroy: ({ context: ctx }) => {
          ctx.onDestroy?.();
          return noop;
        },
      });
      if (context.hlsRef) {
        enqueue.stopChild(context.hlsRef.id);
      }
    }),
    assignInit: assign(({ event }) => {
      assertEvent(event, 'INIT');
      return event.payload;
    }),
    assignDestroy: assign({
      onDestroy: ({ context, event }) => {
        assertEvent(event, 'xstate.done.actor.mount');

        return event.output.goTo === 'tryToPlay' ||
          event.output.goTo === 'paused'
          ? event.output.onDestroy
          : context.onDestroy;
      },
    }),
    assignFallback: assign({
      fallback: ({ context, event }) => {
        assertEvent(event, 'xstate.done.actor.mount');
        return event.output.goTo === 'tryToPlay' ||
          event.output.goTo === 'paused' ||
          event.output.goTo === 'mountFromStream'
          ? event.output.fallback
          : context.fallback;
      },
    }),
    removeFallback: assign({
      // cant remove _ctx, otherwise assign gets bad typing
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fallback: (_ctx) => undefined,
    }),
    seek: ({ context: ctx, event: e }) => {
      const event = e.type === 'INTERNAL_PAUSE_DONE' ? e.payload : e;
      if (event.type === 'SOUGHT' && ctx.audioRef && ctx.audioRef.current) {
        ctx.audioRef.current.currentTime = event.payload.time;
        event.payload.cb(ctx.audioRef.current.currentTime);
      }
    },
    forward: ({ context: ctx, event: e }) => {
      const event = e.type === 'INTERNAL_PAUSE_DONE' ? e.payload : e;
      if (event.type === 'FORWARDED' && ctx.audioRef && ctx.audioRef.current) {
        ctx.audioRef.current.currentTime += event.payload.add;
        event.payload.cb(ctx.audioRef.current.currentTime);
      }
    },
    mountFromStream: assign({
      hlsRef: ({
        context: { audioRef, fallback, maxRetries, onProgress },
        event,
        spawn,
      }) => {
        assertEvent(event, 'xstate.done.actor.mount');
        return spawn(
          fromCallback(({ sendBack }) => {
            const audioEl = audioRef?.current;
            let retries = maxRetries || 0;
            if (audioEl && fallback) {
              const song = fallback.playingSong;
              // IMPORTANT: verify this continues to work when updating hls.js
              // or doing any change on how songs are cached
              const path =
                fallback.offlineSong?.state === DownloadState.DOWNLOADED &&
                fallback.offlineSong?.payload.type === 'browser'
                  ? fallback.offlineSong.payload.url
                  : (song.path as string);
              audioEl.removeAttribute('src');

              const goTo: 'tryToPlay' | 'paused' = fallback.autoPlay
                ? 'tryToPlay'
                : 'paused';
              // let goTo: 'tryToPlay' | 'paused' =
              //   event.type ===
              //     'error.platform.Player.tryToPlay:invocation[0]' ||
              //   fallback.autoPlay
              //     ? 'tryToPlay'
              //     : 'paused';

              // receive((e) => {
              //   if (e.type === 'PAUSED') {
              //     goTo = 'paused';
              //   } else if (e.type === 'PLAYED') {
              //     goTo = 'tryToPlay';
              //   } else if (e.type === 'PLAYBACK_TOGGLED') {
              //     goTo = goTo === 'paused' ? 'tryToPlay' : 'paused';
              //   }
              // });

              if (Hls.isSupported()) {
                const hls = new Hls({
                  startPosition: fallback.progress,
                  xhrSetup: (xhr) => {
                    const { authToken } = fallback;
                    if (authToken) {
                      xhr.setRequestHeader(
                        'Authorization',
                        `Bearer ${authToken}`
                      );
                    }

                    if (fallback.playingSong.workspaceId) {
                      xhr.setRequestHeader(
                        'control-workspace',
                        fallback.playingSong.workspaceId
                      );
                    }
                  },
                });

                try {
                  hls.once(Hls.Events.MANIFEST_PARSED, () => {
                    audioEl.currentTime = fallback.progress;
                    onProgress?.(fallback.progress);
                    sendBack({
                      type: 'INTERNAL_STREAM_MOUNTED',
                      payload: {
                        goTo,
                      },
                    });
                  });

                  hls.loadSource(path);
                  hls.attachMedia(audioEl);

                  hls.on(Hls.Events.ERROR, (e, data) => {
                    // https://github.com/video-dev/hls.js/blob/26b27eb802b582580e8278d29188fd5bf3c874fa/docs/API.md?plain=1#L282
                    // TODO: the demo https://github.com/video-dev/hls.js/blob/794bb0642a3c4d0263c28dcf7618c4593a1314dd/demo/main.js#L724
                    // is quite much more complex at handling this errors
                    console.error(e);
                    console.error(data);
                    if (data.fatal) {
                      switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                          console.log(
                            'fatal network error encountered, trying to recover'
                          );
                          if (retries > 0) {
                            retries -= 1;
                            setTimeout(() => {
                              // https://github.com/video-dev/hls.js/issues/1838#issuecomment-406426627
                              hls.startLoad();
                            }, 10);
                          } else {
                            sendBack({
                              type: 'ERRORED',
                            });
                          }
                          break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                          console.log(
                            'fatal media error encountered, try to recover'
                          );
                          if (retries > 0) {
                            retries -= 1;
                            setTimeout(() => {
                              // https://github.com/video-dev/hls.js/issues/1838#issuecomment-406426627
                              const time = audioEl.currentTime;
                              hls.recoverMediaError();
                              sendBack({
                                type: 'SOUGHT',
                                payload: {
                                  cb: noop,
                                  time,
                                },
                              });
                            }, 10);
                          } else {
                            sendBack({
                              type: 'ERRORED',
                            });
                          }
                          break;
                        default:
                          // cannot recover
                          destroyHls(hls);
                          sendBack({
                            type: 'ERRORED',
                          });
                          break;
                      }
                    }

                    switch (data.details) {
                      case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
                        sendBack({
                          type: 'ERRORED',
                        });
                        break;
                      default:
                        break;
                    }
                  });

                  return () => {
                    destroyHls(hls);
                  };
                } catch (e) {
                  destroyHls(hls);
                  sendBack({
                    type: 'INTERNAL_STREAM_MOUNTED',
                    payload: {
                      goTo: 'unmounted',
                    },
                  });
                  return noop;
                }
              } else {
                audioEl.src = path;
                audioEl.currentTime = fallback.progress;
                onProgress?.(fallback.progress);
                sendBack({
                  type: 'INTERNAL_STREAM_MOUNTED',
                  payload: {
                    goTo,
                  },
                });
                return noop;
              }
            }
            sendBack({
              type: 'INTERNAL_STREAM_MOUNTED',
              payload: {
                goTo: 'unmounted',
              },
            });
            return noop;
          })
        );
      },
    }),
    addToBuffer: assign({
      buffer: ({ event }) => {
        assertEvent(event, 'MOUNTED');
        return event.payload;
      },
    }),
    clearBuffer: assign({
      buffer: ({ context }) => (context.buffer ? undefined : context.buffer),
    }),
    forcePause: ({ context }) => {
      try {
        context.audioRef?.current?.pause();
      } catch (e) {
        console.error(e);
      }
    },
    tryToPause: assign({
      pausingRef: ({
        context: { audioContextRef, audioRef, gainNodeRef },
        event,
        spawn,
      }) =>
        spawn(
          fromCallback(({ sendBack }) => {
            if (
              audioContextRef?.current &&
              audioRef?.current &&
              gainNodeRef?.current &&
              isPlayingAudioElement(audioRef.current)
            ) {
              const gainNode = gainNodeRef.current;
              const context = audioContextRef.current;
              const audio = audioRef.current;
              let timeout: number;
              audio.addEventListener(
                'pause',
                function onPaused() {
                  // pause can also be triggered on end event
                  // which pauses a song after a now one has mounted,
                  // this is easier to see with a long VOLUME_FADE_DURATION in the order of seconds
                  if (timeout) {
                    clearTimeout(timeout);
                  }
                  sendBack({
                    type: 'INTERNAL_PAUSE_DONE',
                    payload: event,
                  });
                },
                {
                  once: true,
                  passive: true,
                }
              );
              // http://alemangui.github.io/ramp-to-value
              gainNode.gain.setValueAtTime(
                gainNode.gain.value,
                context.currentTime
              );
              gainNode.gain.exponentialRampToValueAtTime(
                VOLUME_MIN,
                context.currentTime + VOLUME_FADE_DURATION / 1000
              );
              timeout = window.setTimeout(() => {
                audio.pause();
              }, VOLUME_FADE_DURATION);
            } else {
              sendBack({
                type: 'INTERNAL_PAUSE_DONE',
                payload: event,
              });
            }
          })
        ),
    }),
    destroyPause: assign({
      pausingRef: ({ context }) => {
        if (context.pausingRef) {
          stopChild(context.pausingRef);
        }
        return undefined;
      },
    }),
    forwardToStream: ({ context, event }) => {
      context.hlsRef?.send(event);
    },
    onError: ({ context }) => context.onError?.(),
    addHealthCheck: assign({
      playingRef: ({ context: { audioRef }, spawn }) =>
        spawn(
          fromCallback(({ sendBack }) => {
            function healed() {
              sendBack({
                type: 'HEALTHY',
              });
            }
            audioRef?.current?.addEventListener('timeupdate', healed, {
              once: true,
            });

            return () => {
              audioRef?.current?.removeEventListener('timeupdate', healed);
            };
          })
        ),
    }),
    removeHealthCheck: assign({
      playingRef: ({ context }) => {
        if (context.playingRef) {
          stopChild(context.playingRef);
        }
        return undefined;
      },
    }),
    onHealthy: ({ context }) => {
      context.onHealthy?.();
    },
    addOnUpdate: assign({
      timeUpdateRef: ({ context: { audioRef, onProgress }, spawn }) =>
        spawn(
          fromCallback(() => {
            const unsubscribeRAFInterval = requestAnimationFrameInterval(() => {
              const audioEl = audioRef?.current;
              if (audioEl) {
                onProgress?.(audioEl.currentTime);
              }
            }, 0);

            return () => {
              unsubscribeRAFInterval?.();
            };
          })
        ),
    }),
    removeOnUpdate: assign({
      timeUpdateRef: ({ context }) => {
        if (context.timeUpdateRef) {
          stopChild(context.timeUpdateRef);
        }
        return undefined;
      },
    }),
  },
  actors: {
    mount: fromPromise<
      MountingData,
      Pick<PlayerContext, 'audioRef' | 'buffer' | 'onProgress'> & {
        event: PlayerEvent;
      }
    >(async ({ input: { audioRef, buffer, onProgress, event } }) => {
      const mountData: MountData | undefined =
        buffer ||
        pipe(
          event.type === 'INTERNAL_PAUSE_DONE' ? event.payload : event,
          (e) => (e.type === 'MOUNTED' ? e.payload : undefined)
        );
      if (mountData && audioRef && audioRef.current) {
        const audioEl = audioRef.current;
        if (
          mountData.offlineSong?.state === DownloadState.DOWNLOADED &&
          mountData.offlineSong.payload.type === 'file'
        ) {
          audioEl.src = `track://${mountData.offlineSong.payload.path}`;
          // const filePath = mountData.offlineSong.payload.path;
          // const file = await window.nativeAPI.file.read(filePath);
          // console.log(file);
          // const blob = new Blob([file], {
          //   // mime: 'audio/*',
          //   // type: 'audio/*',
          //   // type: 'audio/x-m4a',
          //   type: 'audio/mp4',
          // });

          // console.log(blob.type);

          // const url = URL.createObjectURL(blob);
          // console.log(url);
          // audioEl.src = url;
          audioEl.currentTime = mountData.progress;
          onProgress?.(mountData.progress);

          audioEl.addEventListener('error', onAudioElError);
          return {
            goTo: mountData.autoPlay ? 'tryToPlay' : 'paused',
            fallback: mountData,
            onDestroy: () => {
              try {
                audioEl.removeEventListener('error', onAudioElError);
                audioEl.pause();
                audioEl.currentTime = 0;
                onProgress?.(0);
              } catch (e) {
                console.error(e);
              }
            },
          };
        }
        return {
          goTo: 'mountFromStream',
          fallback: mountData,
        };
      }
      console.error('not ready to play');
      return {
        goTo: 'unmounted',
      };
    }),
    tryToPlay: fromPromise<void, PlayerContext>(async ({ input }) =>
      playMutable(input)
    ),
  },
}).createMachine({
  context: {},
  schema: {
    context: {} as PlayerContext,
    events: {} as PlayerEvent,
    services: {} as {
      mount: {
        data: MountingData;
      };
      tryToPlay: {
        data: unknown;
      };
    },
  },
  id: 'Player',
  initial: 'idle',
  on: {
    DESTROY: {
      actions: 'destroy',
      target: '#Player.idle',
    },
    UNMOUNTED: {
      actions: 'destroy',
      target: '#Player.unmounted',
    },
    ERRORED: {
      target: '#Player.errored',
    },
    HEALTHY: {
      actions: ['onHealthy'],
    },
  },
  states: {
    idle: {
      on: {
        INIT: {
          actions: 'assignInit',
          target: '#Player.unmounted',
        },
      },
    },
    errored: {
      entry: ['onError'],
      always: '#Player.unmounted',
    },
    unmounted: {
      entry: ['destroy'],
      on: {
        MOUNTED: {
          target: '#Player.mounting',
        },
      },
    },
    mounting: {
      entry: ['destroy'],
      exit: ['clearBuffer'],
      invoke: {
        src: 'mount',
        id: 'mount',
        input: ({ context, event }) => ({
          audioRef: context.audioRef,
          buffer: context.buffer,
          onProgress: context.onProgress,
          event,
        }),
        onDone: [
          {
            actions: ['assignDestroy', 'assignFallback'],
            guard: 'shouldGoToPlay',
            target: '#Player.tryToPlay',
          },
          {
            actions: ['assignDestroy', 'assignFallback'],
            guard: 'shouldGoToPaused',
            target: '#Player.paused',
          },
          {
            actions: ['assignDestroy', 'assignFallback'],
            guard: 'shouldGoToMountFromStream',
            target: '#Player.mountingFromStream',
          },
          {
            target: '#Player.errored',
          },
        ],
        onError: [
          {
            target: '#Player.errored',
          },
        ],
      },
    },
    mountingFromStream: {
      entry: ['mountFromStream'],
      exit: 'removeFallback',
      on: {
        MOUNTED: {
          target: '#Player.mounting',
        },
        PLAYBACK_TOGGLED: {
          actions: ['forwardToStream'],
        },
        PAUSED: {
          actions: ['forwardToStream'],
        },
        PLAYED: {
          actions: ['forwardToStream'],
        },
        INTERNAL_STREAM_MOUNTED: [
          {
            guard: 'shouldGoToPlay',
            target: '#Player.tryToPlay',
          },
          {
            guard: 'shouldGoToPaused',
            target: '#Player.paused',
          },
          {
            target: '#Player.errored',
          },
        ],
      },
    },
    tryToPlay: {
      invoke: {
        src: 'tryToPlay',
        input: ({ context }) => context,
        onDone: [
          {
            target: '#Player.playing',
          },
        ],
        onError: [
          {
            guard: 'hasFallback',
            target: '#Player.mountingFromStream',
          },
          {
            target: '#Player.errored',
          },
        ],
      },
      on: {
        MOUNTED: {
          target: '#Player.mounting',
        },
        PAUSED: {
          actions: ['forcePause'],
          target: '#Player.paused',
        },
        PLAYBACK_TOGGLED: {
          actions: ['forcePause'],
          target: '#Player.paused',
        },
      },
    },
    playing: {
      entry: ['addHealthCheck', 'addOnUpdate'],
      exit: ['removeHealthCheck', 'removeOnUpdate'],
      on: {
        PLAYBACK_TOGGLED: {
          target: '#Player.pausing',
        },
        PAUSED: {
          target: '#Player.pausing',
        },
        ENDED: {
          target: '#Player.paused',
        },
        MOUNTED: {
          target: '#Player.pausing',
        },
        SOUGHT: {
          target: '#Player.pausing',
        },
        FORWARDED: {
          target: '#Player.pausing',
        },
      },
    },
    pausing: {
      entry: ['tryToPause'],
      exit: ['destroyPause'],
      on: {
        MOUNTED: {
          actions: ['addToBuffer'],
        },
        INTERNAL_PAUSE_DONE: [
          {
            guard: 'hasBuffer',
            target: '#Player.mounting',
          },
          {
            // TODO: this could be handler more gracefully:
            // subscribing to the 'seeked' event of the audio element,
            // trying to play wouldn't wait that long, and get canceled by pause,
            // and we could handle other events while waiting more explicitly.
            actions: 'seek',
            guard: 'isSought',
            target: '#Player.tryToPlay',
          },
          {
            actions: 'forward',
            guard: 'isForwarded',
            target: '#Player.tryToPlay',
          },
          {
            guard: 'isMounted',
            target: '#Player.mounting',
          },
          {
            guard: 'isPaused',
            target: '#Player.paused',
          },
        ],
      },
    },
    paused: {
      on: {
        MOUNTED: {
          target: '#Player.mounting',
        },
        PLAYBACK_TOGGLED: {
          target: '#Player.tryToPlay',
        },
        PLAYED: {
          target: '#Player.tryToPlay',
        },
        SOUGHT: {
          actions: 'seek',
          target: '#Player.paused',
        },
        FORWARDED: {
          actions: 'forward',
          target: '#Player.paused',
        },
      },
    },
  },
});
