import { addListener, createAsyncThunk } from '@reduxjs/toolkit';
import type { Option } from 'fp-ts/lib/Option';
import type { ReactNode } from 'react';
import { type TypedUseSelectorHook, useSelector } from 'react-redux';
import type { Action, Store as ReduxStore } from 'redux';
import type { RouterState } from 'redux-first-history';
import type { PersistState } from 'redux-persist';
import type { ThunkDispatch } from 'redux-thunk';
import type { OfflineSong } from '../../common/OfflineSong';
import type {
  FolderId,
  PlaylistId,
  ProjectId,
  SongId,
  WorkspaceId,
} from '../../common/Opaques';
import type { Song } from '../../common/Song';
import type { UploadQueueItem } from '../../common/UploadQueueItem';
import type { ProjectMember } from '../services/api/types/ProjectMember';
import type {
  Project,
  Workspace,
  WorkspaceSubscriptionEnabledFeature,
} from '../services/api/types/Workspace';
import type { WorkspaceRole } from '../services/api/types/WorkspaceMember';
import type { Activity, ActivityUnread } from '../types/Activity';
import type { Album } from '../types/Album';
import type { Artist } from '../types/Artist';
import type { Collaborator } from '../../common/Collaborator';
import type { FileListSort } from '../types/FileListSort';
import type { Folder } from '../types/Folder';
import type { NotificationSettings } from '../types/NotificationSettings';
import type { Playlist } from '../types/Playlist';
import type { Preferences } from '../types/Preferences';
import type { RepeatMode } from '../types/RepeatMode';
import type { SearchItem } from '../types/Search';
import type { WorkspaceProjectRole } from '../types/WorkspaceProjectRole';
import type { HomePath } from '../utils/route-utils';

export interface AutocompleteOptions {
  // TODO (rtqk): construct in the component
  metadataAlbums: string[];
  metadataArtists: string[];
  metadataMasterOwners: string[];
  metadataPublishers: string[];
  titles: string[];
}

export interface RouteScopedState {
  sort?: FileListSort;
}

export interface SongsStateType {
  albums: Album[];
  artists: Artist[];
  autocompleteOptions: AutocompleteOptions;
  centerSelected: boolean;
  error?: string;
  /**
   * @deprecated should deprecate eventually and replace for something more useful
   */
  loading: boolean;
  queuedTrackSelection: SongId | undefined;
  routeScopedState: Record<string, RouteScopedState>;
  selectedFolderId?: FolderId;
  selectedPlaylistId?: PlaylistId;
  songsWithErrors: string[];
  visibleAlbums: Album[];
  visibleArtists: Artist[];
  visibleSongIds: SongId[];
}

export interface AlertType {
  title?: string;
  message: string;
  button?: string;
}

export type ShareModeSelectValue = WorkspaceProjectRole | 'owner';

export type ShareModeSelectOption =
  | ShareModeSelectValue
  | undefined
  | 'remove member'
  | 'cancel invitation'
  | 'reinvite'
  | 'divider';

export interface LibraryRoleMenuIdentifier {
  type: 'library role selector';
  top: number;
  left: number;
  options: ShareModeSelectOption[];
  value: ShareModeSelectValue;
  setShareMode: (mode: ShareModeSelectOption) => void;
  prev: GlobalStateType['activeIdentifier'];
}

export enum LoadedItem {
  PlayerMemo,
  CenterPaneContent,
  TopBatMemo,
  LeftPane,
  RightPane,
}

export type WorkspaceShareModeSelectValue = WorkspaceRole;

export type WorkspaceShareModeSelectOption =
  | WorkspaceShareModeSelectValue
  | undefined
  | 'remove member'
  | 'cancel invitation'
  // | 'reinvite'
  | 'divider';

export interface WorkspaceRoleMenuIdentifier {
  type: 'workspace role selector';
  top: number;
  left: number;
  options: WorkspaceShareModeSelectOption[];
  value: WorkspaceShareModeSelectValue;
  setShareMode: (mode: WorkspaceShareModeSelectOption) => void;
  prev: GlobalStateType['activeIdentifier'];
}

export type GlobalStateType = {
  isLoading: boolean;
  alert?: AlertType;
  activeIdentifier:
    | 'centerPane'
    | 'contactMenu'
    | 'leftPane'
    | 'playQueue'
    | 'rightPane'
    | 'songList'
    | LibraryRoleMenuIdentifier
    | WorkspaceRoleMenuIdentifier;
  fetchedStep: [number, number];
  loadedItems: LoadedItem[];
  isShownProgress: boolean;
};

// TODO: remove enum
export enum UploadState {
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface UploadingSong {
  deletedAt?: Date;
  progress: number;
  state: UploadState;
  uploadQueueItem: UploadQueueItem;
}

export interface UploadsStateType {
  // // Song ids of currently uploading artworks
  // songArtworks: Set<string>;

  // Currently pending, uploading, or recently uploaded songs
  songs: Record<SongId, UploadingSong>;
}

export type SearchStateType = Partial<
  Record<
    WorkspaceId,
    {
      searches: SearchItem[];
    }
  >
>;

export interface ActivityStateType {
  activities: Activity[];
  users: Collaborator[];
  activityUnread?: ActivityUnread;
  activityTooltipShown: boolean;
  ignoredFriends: string[];
}

export enum SnackType {
  SUCCESS,
  WARNING,
  ERROR,
  NOACCESS,
  SAFE,
}

export interface Snackbar {
  key: string;
  message: string | ReactNode;
  dismissed?: boolean;
  type: SnackType;
  goTo?: string;
}

type Dialog =
  | DeleteFilesDialogOption
  | DeleteOrLeavePlaylistDialogOption
  | DeleteSelectedFolderDialogOption
  | DeleteSelectedProjectDialogOption
  | DuplicateSongDialogOption
  | FailedResumeUploadDialogOption
  | FeedbackDialogOption
  | LeaveProjectDialogOption
  | NonAudioFilesDialogOption
  | PermissionDeniedDialogOption
  | RemoveDownloadPlaylistDialogOption
  | RemoveFilesFromPlaylistDialogOption
  | RemoveMemberFromProjectDialogOption
  | RemoveMemberFromWorkspaceDialogOption
  | SignOutDialogOption
  | SignOutOfAllDevicesDialogOption
  | UnpackFolderDialogOption
  | undefined;

type Modal =
  | DebugModal
  | EditFolderModal
  | ShortcutsModal
  | InviteMembersModal
  | NewFolderModal
  | NewFolderModalSelectedProject
  | NewPlaylistModal
  | NewProjectModal
  | NoneModal
  | ProjectSettingsModal
  | RenamePlaylistModal
  | RenameProjectModal
  | SettingsModal
  | SharePlaylistModal
  | ShareSongModal
  | UpsellWorkspaceEmbeddedModal
  | UpsellWorkspaceModal
  | WorkspaceModal;

export interface ViewStateType {
  dialog: Dialog;
  historyLength: number;
  historyPosition: number;
  isDirectUpgrade: boolean;
  menuShown: boolean;
  modal: Modal;
  notification?: string;
  plansDialogShown: boolean;
  snackbars: Snackbar[];
  statusBarShown: boolean;
  updateBubbleShown: boolean;
  upsellDialogFeature?: WorkspaceSubscriptionEnabledFeature;
  upsellDialogShown: boolean;
}

export interface NoneModal {
  type: 'none';
}

export interface ShortcutsModal {
  type: 'shortcut';
}

export interface DebugModal {
  type: 'debug';
}

export interface NewFolderModalSelectedProject {
  type: 'new folder in selected project';
}

export interface NewPlaylistModal {
  type: 'new playlist';
  projectId?: ProjectId; // to locate the project in the playlist tree
  addSelectedSongs?: boolean;
  selectedPlaylist?: Playlist; // to locate the playlist in the playlist tree
  folderId?: FolderId;
}

export interface SharePlaylistModal {
  type: 'share playlist';
  // pass only the playlistId in case the Playlist changes and the modal has to reflect the change
  playlistId: PlaylistId;
}

export interface ShareSongModal {
  type: 'share song';
  songId: SongId;
  workspaceId: WorkspaceId;
}

export interface NewFolderModal {
  type: 'new folder';
  projectId?: ProjectId;
  folderId?: FolderId;
}

export interface InviteMembersModal {
  type: 'invite members';
}

export interface EditFolderModal {
  folder: Folder;
  type: 'edit folder';
}

export interface RenamePlaylistModal {
  playlistId: PlaylistId;
  type: 'rename playlist';
}

export enum WorkspaceModalTab {
  invite,
  members,
  details,
  plan,
}

export interface WorkspaceModal {
  type: 'workspace';
  workspaceId: WorkspaceId;
  tab: WorkspaceModalTab;
}

export interface NewProjectModal {
  type: 'new project';
}

export interface ProjectSettingsModal {
  project: Project;
  type: 'project settings';
}

export interface UpsellWorkspaceEmbeddedModal {
  workspace: Workspace;
  type: 'upsell embedded';
}

export interface UpsellWorkspaceModal {
  workspace: Workspace;
  type: 'upsell';
}

export interface RenameProjectModal {
  project: Project;
  type: 'rename project';
}

export interface SettingsModal {
  type: 'settings';
}

export interface SignOutDialogOption {
  type: 'sign out';
}

export interface PermissionDeniedDialogOption {
  type: 'permission denied';
}

export interface LeaveProjectDialogOption {
  type: 'leave project';
  project: Project;
}

export interface DeleteSelectedProjectDialogOption {
  type: 'delete selected project';
  project: Project;
}

export interface DeleteOrLeavePlaylistDialogOption {
  type: 'delete or leave playlist';
  playlist: Playlist;
}

export interface RemoveDownloadPlaylistDialogOption {
  type: 'remove download';
  playlist: Playlist;
}

export interface DeleteSelectedFolderDialogOption {
  type: 'delete selected folder';
  folder: Folder;
}

export interface RemoveMemberFromWorkspaceDialogOption {
  type: 'remove member from workspace';
  collaborator: Partial<Collaborator>;
}

export interface DeleteFilesDialogOption {
  type: 'delete files';
  files: Song[];
}

export interface RemoveFilesFromPlaylistDialogOption {
  type: 'remove files from playlist';
  files: Song[];
  playlist: Playlist;
}

export interface UnpackFolderDialogOption {
  type: 'unpack folder';
  folder: Folder;
}

export interface SignOutOfAllDevicesDialogOption {
  type: 'sign out of all devices';
}

export interface FeedbackDialogOption {
  type: 'feedback';
}

export interface RemoveMemberFromProjectDialogOption {
  name: string;
  project: Project;
  type: 'remove member from project';
  userId: string;
}

export interface DuplicateSongDialogOption {
  type: 'duplicated song';
  title: string;
  message: string;
}

export interface FailedResumeUploadDialogOption {
  type: 'failed resume upload';
}

export interface NonAudioFilesDialogOption {
  type: 'failed to import';
  mime?: string | false;
}

export enum StatsState {
  HOURS,
  TOTAL_TIME,
}

export type Size = { width: number; height: number };

export enum RightPaneComponent {
  FILE_ATTACHMENTS = 'FILE_ATTACHMENTS',
  FILE_COMMENTS = 'FILE_COMMENTS',
  FILE_INFO = 'FILE_INFO',
  FILE_LYRICS = 'FILE_LYRICS',
  PLAYLIST_ATTACHMENTS = 'PLAYLIST_ATTACHMENTS',
  PLAYLIST_INFO = 'PLAYLIST_INFO',
  WORKSPACE_INFO = 'WORKSPACE_INFO',
}

export enum PaneCursor {
  NONE,
  LEFT,
  RIGHT,
  BOTH,
}

export interface PanesType {
  windowSize: Size;
  debug: {
    hidePanes: boolean;
  };
  left: {
    expanded: string[];
    flexMaxSize: number;
    isLibraryOpen: boolean;
    leftPaneWidth: number;
    statsState: StatsState;
    startExpanded: boolean;
  };
  right: {
    component: RightPaneComponent;
    defaultAccordionOpen: RightPaneDefaultAccordion[];
    displaySize: Size;
    isShown: boolean;
    size: Size;
  };
}

export enum RightPaneDefaultAccordion {
  UPSELL,
  ARTWORK,
  DESCRIPTION,
  METADATA,
  PLAYLISTS,
  FILE,
}

export interface SettingsStateType {
  _persist?: PersistState;
  duplicatedAssignSongAlertHidden: boolean;
  duplicatedUploadSongAlertHidden: boolean;
  failedResumedUploadHidden: boolean;
  notificationSettings?: NotificationSettings;
  preferences: Preferences;
  showTutorial: boolean;
  showWhatIsNew: boolean;
}

/**
 * The details about the latest downloaded app update.
 */
export interface UpdateInfoType {
  version?: string;
  releaseNotes?: string;
}

export interface OAuthTokenType {
  refreshToken: string;
  accessToken: string;
}

export interface TokensType {
  auth?: string;
  email?: string;
}

export interface WorkspaceScopedType {
  lastRoute?: { pathname: string; search: string };
  expanded: string[];
}

export interface WorkspaceType {
  selectedWorkspaceId?: WorkspaceId;
  selectedProjectId?: ProjectId;
  projectMembers: ProjectMember[]; // members from the selected project
  scoped: Record<WorkspaceId, WorkspaceScopedType>;
  debug: {
    fetchWorkspaces: boolean;
  };
}

export interface WorkspaceSongId {
  workspaceId: WorkspaceId;
  songId: SongId;
}

export type PlayerQueueItemInput = [HomePath, SongId];
export type PlayerQueueItem = [HomePath, SongId, number];
export type PlayerQueue = PlayerQueueItem[];

export interface PlayerStateType {
  _persist?: PersistState;
  playingSongIndex: Option<number>;
  playbackState: boolean; // UI
  isPlaying: boolean; // internal state, not to be used in the UI
  playbackProgress: number;
  /**
   * The volume selected by the seekbar in the UI.
   */
  volume: number;

  /**
   * True if the playback is muted.
   */
  isMuted: boolean;

  /**
   * The actual volume of the audio player, can be different from volume during fades or when muted.
   */
  playerVolume: number;
  repeatMode: RepeatMode;
  shuffle: boolean;
  queueSongIds: PlayerQueue;
  songsWithErrors: string[];
}

export interface DownloaderStateType {
  playlists: PlaylistId[];
  // NOTE: the app works under the assumption the songs keys are added in the order of playlists custom order, so it doubles as an index indirectly and avoids searching for the playlist again to resume download.
  songs: Record<SongId, PlaylistId[]>;
  offlineSongs: Record<SongId, OfflineSong>;
}

export interface DebuggerStateType {
  logins: {
    deviceToken?: string;
    email: string;
    password: string;
  }[];
}

export interface ControlStateType {
  _persist: PersistState | undefined;
  activity: ActivityStateType;
  api: any;
  debugger: DebuggerStateType;
  downloader: DownloaderStateType;
  global: GlobalStateType;
  loggedUser: Collaborator | undefined;
  panes: PanesType;
  player: PlayerStateType;
  router: RouterState;
  search: SearchStateType;
  settings: SettingsStateType;
  songs: SongsStateType;
  tokens: TokensType;
  updateInfo: UpdateInfoType;
  uploads: UploadsStateType;
  view: ViewStateType;
  workspace: WorkspaceType;
}

export type GetState = () => ControlStateType;

export type Dispatch = ThunkDispatch<ControlStateType, unknown, Action<string>>;

export type Store = ReduxStore<ControlStateType, Action<string>>;

/**
 * @deprecated
 */
export type ThunkApiConfig = { dispatch: Dispatch; state: ControlStateType };

export const useAppSelector: TypedUseSelectorHook<ControlStateType> =
  useSelector;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: ControlStateType;
  dispatch: Dispatch;
}>();

export const addAppListener = addListener.withTypes<
  ControlStateType,
  Dispatch
>();
