import type { Option } from 'fp-ts/lib/Option';
import {
  alt,
  chain,
  fromNullable,
  map,
  none,
  toNullable,
  toUndefined,
} from 'fp-ts/lib/Option';
import { flow, pipe } from 'fp-ts/lib/function';
import type { Location } from 'history';
import * as LO from 'monocle-ts/Optional';
import type { match } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import type { LOCATION_CHANGE, RouterActions } from 'redux-first-history';
import type {
  FolderId,
  PlaylistId,
  ProjectId,
  WorkspaceId,
} from '../../common/Opaques';
import {
  ALL_FILES,
  COLLABORATOR,
  FILTER,
  FOLDER,
  PLAYLIST,
  STARRED,
  WORKSPACE,
} from '../constants/routes';

type CollaboratorDetailPath = {
  type: 'collaborator';
  match: match<{
    workspaceId: WorkspaceId;
    userId: string;
  }>;
};

type WorkspacePath = {
  type: 'workspace';
  match: match<{
    workspaceId: WorkspaceId;
  }>;
};

type WorkspaceAllFilesPath = {
  type: 'home';
  match: match<{
    workspaceId: WorkspaceId;
  }>;
};

type FolderPath = {
  type: 'folder';
  match: match<{
    workspaceId: WorkspaceId;
    libraryId: ProjectId;
    folderId: FolderId;
  }>;
};

export type PlaylistPath = {
  type: 'playlist';
  match: match<{
    workspaceId: WorkspaceId;
    libraryId?: ProjectId;
    playlistId: PlaylistId;
  }>;
};

type StarredPath = {
  type: 'starred';
  match: match<{
    workspaceId: WorkspaceId;
  }>;
};

export type AllFilesView = 'all' | 'mine' | 'shared';

type AllFilesPath = {
  type: 'all files';
  match: match<{
    workspaceId: WorkspaceId;
    view?: AllFilesView;
  }>;
};

type AlbumPath = {
  type: 'album';
  match: match<{
    workspaceId: WorkspaceId;
    album: string;
  }>;
};

type FilterPath = {
  type: 'filter';
  match: match<{
    workspaceId: WorkspaceId;
    category: string;
    filterString: string;
  }>;
};

export type HomePath =
  | AlbumPath
  | AllFilesPath
  | CollaboratorDetailPath
  | FilterPath
  | FolderPath
  | PlaylistPath
  | StarredPath
  | WorkspaceAllFilesPath
  | WorkspacePath;

export function matchPossiblePaths({
  pathname,
  search,
}: {
  pathname: string;
  search: string;
}): Option<HomePath> {
  return pipe(
    none,
    alt(() => {
      // https://github.com/remix-run/react-router/issues/7233#issuecomment-609032939
      const params = new URLSearchParams(search);

      return pipe(
        matchPath(pathname ?? '', {
          path: COLLABORATOR,
          exact: true,
          strict: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'collaborator',
              match: {
                ...r,
                params: {
                  ...r.params,
                  workspaceId: params.get('workspaceId') ?? undefined,
                },
              },
            } as HomePath)
        )
      );
    }),
    alt(() => {
      // https://github.com/remix-run/react-router/issues/7233#issuecomment-609032939
      const params = new URLSearchParams(search);

      return pipe(
        matchPath(pathname ?? '', {
          path: FOLDER,
          exact: true,
          strict: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'folder',
              match: {
                ...r,
                params: {
                  ...r.params,
                  workspaceId: params.get('workspaceId') ?? undefined,
                  libraryId: params.get('projectId') ?? undefined,
                },
              },
            } as HomePath)
        )
      );
    }),
    alt(() => {
      // https://github.com/remix-run/react-router/issues/7233#issuecomment-609032939
      const params = new URLSearchParams(search);
      const workspaceId = params.get('workspaceId');
      if (workspaceId) {
        return pipe(
          matchPath(pathname, {
            path: PLAYLIST,
            exact: true,
            strict: true,
          }),
          fromNullable,
          map(
            (r) =>
              ({
                type: 'playlist',
                match: {
                  ...r,
                  params: {
                    ...r.params,
                    workspaceId: params.get('workspaceId') ?? undefined,
                    libraryId: params.get('projectId') ?? undefined,
                  },
                },
              } as HomePath)
          )
        );
      }
      return none;
    }),
    alt(() =>
      pipe(
        matchPath(pathname, {
          path: WORKSPACE,
          exact: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'workspace',
              match: r,
            } as HomePath)
        )
      )
    ),
    alt(() =>
      pipe(
        matchPath(pathname, {
          path: STARRED,
          exact: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'starred',
              match: r,
            } as HomePath)
        )
      )
    ),
    alt(() => {
      const params = new URLSearchParams(search);

      return pipe(
        matchPath(pathname, {
          path: ALL_FILES,
          exact: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'all files',
              match: {
                ...r,
                params: {
                  ...r.params,
                  view: params.get('view') ?? undefined,
                },
              },
            } as HomePath)
        )
      );
    }),
    alt(() => {
      // https://github.com/remix-run/react-router/issues/7233#issuecomment-609032939
      const params = new URLSearchParams(search);
      return pipe(
        matchPath(pathname, {
          path: FILTER,
          exact: true,
          strict: true,
        }),
        fromNullable,
        map(
          (r) =>
            ({
              type: 'filter',
              match: {
                ...r,
                params: {
                  ...r.params,
                  workspaceId: params.get('workspaceId') ?? undefined,
                },
              },
            } as HomePath)
        )
      );
    })
  );
}

export const matchPossiblePathsNullable = flow(
  fromNullable<Location<unknown> | null | undefined>,
  chain(matchPossiblePaths),
  toNullable
);

export function isWorkspacePath(path: HomePath): path is WorkspacePath {
  return path.type === 'workspace';
}

export function isFolderPath(path: HomePath): path is FolderPath {
  return path.type === 'folder';
}

export function isPlaylistPath(path: HomePath): path is PlaylistPath {
  return path.type === 'playlist';
}

export function isStarredPath(path: HomePath): path is StarredPath {
  return path.type === 'starred';
}

export function isAllFilesPath(path: HomePath): path is AllFilesPath {
  return path.type === 'all files';
}

export function isMeaningfulPath(
  path: HomePath
): path is Exclude<HomePath, WorkspacePath> {
  return path.type !== 'workspace';
}

export const workspaceIdLens = pipe(
  LO.id<Option<HomePath>>(),
  LO.some,
  LO.prop('match'),
  LO.prop('params'),
  LO.prop('workspaceId')
);

export const playlistIdLens = pipe(
  LO.id<Option<HomePath>>(),
  LO.some,
  LO.filter(isPlaylistPath),
  LO.prop('match'),
  LO.prop('params'),
  LO.prop('playlistId')
);

export const folderIdFromFolderPath = flow(
  matchPossiblePaths,
  pipe(
    LO.id<Option<HomePath>>(),
    LO.some,
    LO.filter(isFolderPath),
    LO.prop('match'),
    LO.prop('params'),
    LO.prop('folderId')
  ).getOption
);

export const projectIdFromPath = flow(
  matchPossiblePaths,
  pipe(
    LO.id<Option<HomePath>>(),
    LO.some,
    LO.filter(
      (x): x is FolderPath | PlaylistPath =>
        isFolderPath(x) || isPlaylistPath(x)
    ),
    LO.prop('match'),
    LO.prop('params'),
    LO.prop('libraryId')
  ).getOption
);

export const getFolderIdFromFolderPath = flow(
  folderIdFromFolderPath,
  toUndefined
);

export const getPlaylistIdFromPath = flow(
  matchPossiblePaths,
  playlistIdLens.getOption
);

export function mkWorkspacePath(workspaceId: WorkspaceId) {
  return `/workspace/${workspaceId}` as const;
}

export function mkAllFilesPath(workspaceId: WorkspaceId, view?: AllFilesView) {
  const params = new URLSearchParams();
  if (view) {
    params.append('view', view);
  }

  return `/workspace/${workspaceId}/all?${params.toString()}` as const;
}

export function mkStarredPath(workspaceId: WorkspaceId) {
  return `/workspace/${workspaceId}/starred` as const;
}

export function mkFolderPath(
  folderId: FolderId,
  workspaceId?: string | undefined | null,
  projectId?: ProjectId | undefined | null
) {
  const params = new URLSearchParams();
  if (workspaceId) {
    params.append('workspaceId', workspaceId);
  }
  if (projectId) {
    params.append('projectId', projectId);
  }
  return `/folder/${folderId}?${params.toString()}` as const;
}

export function mkPlaylistPath(
  playlistId: PlaylistId,
  workspaceId: string,
  projectId?: ProjectId | undefined | null
) {
  const params = new URLSearchParams();
  if (workspaceId) {
    params.append('workspaceId', workspaceId);
  }
  if (projectId) {
    params.append('projectId', projectId);
  }
  return `/playlist/${playlistId}?${params.toString()}` as const;
}

export function mkAlbumPath(album: string, workspaceId: WorkspaceId) {
  const params = new URLSearchParams();
  if (workspaceId) {
    params.append('workspaceId', workspaceId);
  }
  return `/album/${album}?${params.toString()}` as const;
}

export function mkCollaboratorPath(
  section: string,
  workspaceId?: string | undefined | null
) {
  const params = new URLSearchParams();
  if (workspaceId) {
    params.append('workspaceId', workspaceId);
  }
  return `/collaborator/${section}?${params.toString()}` as const;
}

export function urlToHomePath(url: string) {
  const { pathname, search } = new URL(url, 'https://vollume.com/');
  return matchPossiblePaths({ pathname, search });
}

export const mkPlaylistHomePath = flow(mkPlaylistPath, urlToHomePath);
export const mkAlbumHomePath = flow(mkAlbumPath, urlToHomePath);

export const mkAllFilesHomePath = flow(mkAllFilesPath, urlToHomePath);
export const mkAllFilesHomePathNullable = flow(
  fromNullable<WorkspaceId | undefined | null>,
  map(mkAllFilesPath),
  chain(urlToHomePath),
  toNullable
);

export function URLfromHomePath(homePath: HomePath): string | undefined {
  switch (homePath.type) {
    case 'workspace':
      return mkWorkspacePath(homePath.match.params.workspaceId);
    case 'folder':
      return mkFolderPath(
        homePath.match.params.folderId,
        homePath.match.params.workspaceId,
        homePath.match.params.libraryId
      );
    case 'playlist':
      return mkPlaylistPath(
        homePath.match.params.playlistId,
        homePath.match.params.workspaceId,
        homePath.match.params.libraryId
      );
    case 'starred':
      return mkStarredPath(homePath.match.params.workspaceId);
    case 'all files':
      return mkAllFilesPath(homePath.match.params.workspaceId);
    default:
      return undefined;
  }
}

export type LocationChangeAction = Extract<
  RouterActions,
  { type: typeof LOCATION_CHANGE }
>;
