// export const ARTIST = '/artist/:artistName';
// export const PLAYLIST = '/playlist/:playlistId';
// export const SHARED_WITH_YOU = '/shared-with-you';
// export const STARRED = '/starred';
export const ADD_PROFILE_PICTURE = '/add-profile-picture';
export const ALBUM = '/album/:albumName';
export const ALBUMS = '/albums';
export const ALL_FILES = '/workspace/:workspaceId/all';
export const ARTISTS = '/artists';
export const COLLABORATOR = '/collaborator/:userId';
export const COLLABORATORS = '/collaborators';
export const FILTER = '/filter/:category/:filterString';
export const FOLDER = '/folder/:folderId';
export const HOME = '/';
export const NAME_WORKSPACE = '/name-workspace';
export const PLAYLIST = '/playlist/:playlistId';
export const RESET_PASSWORD = '/reset-password';
export const SHAREDLINKS = '/sharedlinks';
export const SIGNIN = '/signin';
export const SIGNUP = '/signup';
export const STARRED = '/workspace/:workspaceId/starred';
export const WORKSPACE = '/workspace/:workspaceId';
