/* eslint-disable no-param-reassign */
import { pipe } from 'fp-ts/lib/function';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
export function formatTimeAgo(timestamp: Date) {
  const diff = new Date().getTime() - timestamp.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  let format = '';

  if (years > 1) {
    format = `${years} years`;
  } else if (years === 1) {
    format = `${years} year`;
  } else if (months > 1) {
    format = `${months} months`;
  } else if (months === 1) {
    format = `${months} month`;
  } else if (days > 1) {
    format = `${days} days`;
  } else if (days === 1) {
    format = `${days} day`;
  } else if (hours > 1) {
    format = `${hours} hours`;
  } else if (hours === 1) {
    format = `${hours} hour`;
  } else if (minutes > 1) {
    format = `${minutes} minutes`;
  } else if (minutes === 1) {
    format = `${minutes} minute`;
  } else if (seconds > 1) {
    format = `${seconds} seconds`;
  } else {
    format = `just now`;
  }

  return format;
}

export function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  // const seconds = Math.ceil(duration % 60)
  //   .toString(10)
  //   .padStart(2, '0');

  return `${hours ? `${hours} hr ` : ''}${minutes} min`;
}

export function formatDurationShort(duration: number): string {
  const isNegative = duration < 0;
  const durTime = isNegative ? Math.abs(duration) : duration;

  const hours = Math.floor(durTime / 3600);
  const minutes = Math.floor((durTime - hours * 3600) / 60);

  const secondsStr = Math.round(durTime % 60)
    .toString()
    .padStart(2, '0');

  const str =
    hours > 0
      ? `${hours}:${minutes.toString().padStart(2, '0')}:${secondsStr}`
      : `${minutes}:${secondsStr}`;

  return isNegative ? `-${str}` : str;
}

interface FormatSizeOptions {
  decimals?: number;
  showNonsignificantDecimals?: boolean;
}
/**
 * @param size in bytes
 * @param options decimals: number, showZeroDecimals: boolean
 * @param options.decimals number of decimals to show, default 1
 * @param options.showNonsignificantDecimals show 0 decimals, default false
 * @description Format bytes to human readable size
 * @example
 * formatSize(1024) // 1 KB
 * formatSize(1024, { decimals: 2 }) // 1 KB
 * formatSize(1024, { decimals: 2, showNonsignificantDecimals: true }) // 1.00 KB
 * formatSize(1025, { decimals: 2 }) // 1.01 KB
 */
export function formatSize(bytes: number, options?: FormatSizeOptions) {
  if (!+bytes) return '0 Bytes';
  const decimals = options?.decimals ?? 1;
  const showNonsignificantDecimals =
    options?.showNonsignificantDecimals ?? false;

  // const k = 1024;
  const k = 1000; // note: to match macOS
  const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const amount = (bytes / k ** i).toFixed(decimals);
  return `${
    showNonsignificantDecimals ? amount : amount.replace(/\.0+$/, '')
  } ${unit[i]}`;
}

/**
 *
 * {@link https://www.w3resource.com/javascript/form/email-validation.php}
 */
export function validateEmail(email: string) {
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(email).toLowerCase());
}

/**
 *
 * {@link https://www.w3resource.com/javascript/form/email-validation.php}
 */
export function extractEmails(email: string): string[] {
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi; // same as validateEmail but with gi flags
  return pipe(email.matchAll(re), (results) => {
    const response: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const result of results) {
      const extractedEmail = result[0];
      if (extractedEmail) {
        response.push(extractedEmail);
      }
    }
    return response;
  });
}

export function pushIfNotExist(array: any[], element: any, key = '') {
  if (key) {
    if (array.findIndex((el) => el[key] === element[key]) === -1) {
      array.push(element);
    }
  } else if (array.indexOf(element) === -1) {
    array.push(element);
  }
}

export function firstAndLastFromArray(array: string[]) {
  if (array.length === 1 && array[0] && array[0].length > 0) {
    return array[0].charAt(0);
  }

  const last = array[array.length - 1];

  if (
    array.length > 1 &&
    array[0] &&
    array[0].length > 0 &&
    last &&
    last.length > 0
  ) {
    return array[0].charAt(0) + last.charAt(0);
  }

  return '';
}

export function initialsFromName(name: string) {
  return firstAndLastFromArray(
    name.split(/&|\s/).filter(Boolean)
  ).toUpperCase();
}

export function formatDate(date: Date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return `${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()} at ${
    date.getHours() % 12
  }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
}

export function isPlural(value: number) {
  return value !== 1;
}

export function pluralize(
  singularWord: string,
  count: number,
  pluralWord?: string
) {
  if (isPlural(count)) {
    if (pluralWord) {
      return pluralWord;
    }

    switch (singularWord) {
      case 'this':
        return 'these';
      default: {
        return `${singularWord}s`;
      }
    }
  }
  return singularWord;
}

export function isNotEmptyOrOnlyWhiteSpaces(value: string): boolean {
  return /^(?!\s*$).+/.test(value);
}

export function truncateAt(value: string, length: number) {
  return value.length > length ? `${value.substr(0, length)}...` : value;
}

export function capitalizeFirstLetter(value: string) {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function reviverToDate<T>(_key: unknown, value: T): Date | T {
  return typeof value === 'string' &&
    value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    ? new Date(value)
    : value;
}

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
