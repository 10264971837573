import { noop, throttle } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import type { OfflineSong } from '../../common/OfflineSong';
import type { Song } from '../../common/Song';
import { updatePlaybackProgress as updatePlaybackProgressAction } from '../actions/player/player';
import { songEnded } from '../actions/player/songEnded';
import { MachineContext } from '../machines';
import type { Dispatch } from '../reducers/types';
import { useAppSelector } from '../reducers/types';
import { playingSongSelector } from '../store/selectors';
import { isChrome } from '../utils/os-utils';
import { getSongSubtitle } from '../utils/song-utils';
import { formatDurationShort } from '../utils/string-utils';

const VOLUME_FADE_DURATION = 30;
const VOLUME_MIN = 0.0001;

function getSongDuration(
  songRef: React.RefObject<Song | undefined> | undefined
): number {
  return Number(songRef?.current?.metadata?.duration) || 0;
}

export function usePlayer(
  song?: Song,
  offlineSong?: OfflineSong,
  onSongEnded?: () => void,
  isMuted = false,
  playerVolume = 0,
  updatePlaybackProgress?: (progress: number) => void,
  onSought?: (time: number, cb: (progress: number) => void) => void
) {
  // found using refs an interestingly simple way to keep the PlayerMachine synced
  // but mostly to keep the migration simple, might remove later for explicitness
  const audioRef = useRef<HTMLAudioElement>(undefined);
  const songRef = useRef(song);
  songRef.current = song;
  const isDurationToggled = useRef(true);
  const audioContextRef = useRef<AudioContext>(undefined);
  const gainNodeRef = useRef<GainNode>(undefined);
  const playerVolumeRef = useRef(playerVolume);
  playerVolumeRef.current = isMuted ? 0 : playerVolume;
  const [localPlaybackProgress, setLocalPlaybackProgress] = useState(0);
  const [durationFormatted, setDurationFormatted] = useState(
    song
      ? formatDurationShort(
          isDurationToggled.current
            ? getSongDuration(songRef)
            : localPlaybackProgress - getSongDuration(songRef)
        )
      : '0:00'
  );
  const [progressFormatted, setProgressFormatted] = useState(
    formatDurationShort(localPlaybackProgress)
  );

  const handleShowDuration = useCallback(() => {
    const progress = Math.round(audioRef.current?.currentTime || 0);
    setProgressFormatted(formatDurationShort(progress));
    setDurationFormatted(
      formatDurationShort(
        isDurationToggled.current
          ? getSongDuration(songRef)
          : progress - getSongDuration(songRef)
      )
    );
  }, []);

  const setAudioRef = useCallback(
    // eslint-disable-next-line consistent-return
    (node: HTMLAudioElement | null) => {
      if (node !== null && audioContextRef.current === undefined) {
        audioRef.current = node;
        if (onSongEnded) {
          node.addEventListener('ended', onSongEnded);
          return () => {
            node.removeEventListener('ended', onSongEnded);
          };
        }
      }
    },
    [onSongEnded]
  );

  const handleToggleDuration = useCallback(() => {
    isDurationToggled.current = !isDurationToggled.current;
    handleShowDuration();
  }, [handleShowDuration]);

  const setProgress = useMemo(() => {
    const updatePlaybackProgressThrottled = throttle(
      updatePlaybackProgress || noop,
      1000,
      { leading: true, trailing: true }
    );

    return (progress: number) => {
      // when pausing, seeking, playing next/previous the song fades
      // therefore progress is not set to 0 immediately
      // this can cause the progress bar to flicker
      setLocalPlaybackProgress(progress);
      updatePlaybackProgressThrottled(progress);
      handleShowDuration();
    };
  }, [handleShowDuration, setLocalPlaybackProgress, updatePlaybackProgress]);

  useEffect(() => {
    const volume = isMuted ? 0 : playerVolume;
    if (isChrome()) {
      const gainNode = gainNodeRef.current;
      const context = audioContextRef.current;
      if (gainNode && context) {
        gainNode.gain.setValueAtTime(gainNode.gain.value, context.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(
          Math.max(volume, VOLUME_MIN),
          context.currentTime + VOLUME_FADE_DURATION / 1000
        );
      }
    } else if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [isMuted, playerVolume]);

  const seekTo = useCallback(
    (time: number) => {
      setProgress(time); // to avoid flicker of the seekbar
      onSought?.(time, setProgress);
    },
    [onSought, setProgress]
  );

  return useMemo(() => {
    return {
      audioContextRef,
      audioRef,
      durationFormatted,
      gainNodeRef,
      handleToggleDuration,
      localDuration: Number(song?.metadata.duration) || 0,
      localPlaybackProgress,
      playerVolumeRef,
      progressFormatted,
      seekTo,
      setAudioRef,
      setProgress,
      songSubtitle: getSongSubtitle(song, offlineSong),
    };
  }, [
    durationFormatted,
    handleToggleDuration,
    localPlaybackProgress,
    offlineSong,
    progressFormatted,
    seekTo,
    setAudioRef,
    setProgress,
    song,
  ]);
}

interface PlayerControllerContextValue {
  audioContextRef: React.RefObject<AudioContext | undefined>;
  audioRef: React.RefObject<HTMLAudioElement | undefined>;
  gainNodeRef: React.RefObject<GainNode | undefined>;
  handleToggleDuration: () => void;
  playerVolumeRef: React.RefObject<number>;
  seekTo: (time: number) => void;
  setAudioRef: (node: HTMLAudioElement | null) => void;
  setProgress: (progress: number) => void;
  songSubtitle: string;
}

const PlayerControllerContext = createContext<PlayerControllerContextValue>(
  null!
);

export function usePlayerContext() {
  const context = useContext(PlayerControllerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
}

interface PlayerProgressContextValue {
  durationFormatted: string;
  localDuration: number;
  localPlaybackProgress: number;
  progressFormatted: string;
}

const PlayerProgressContext = createContext<PlayerProgressContextValue>(null!);

export function usePlayerProgress() {
  const context = useContext(PlayerProgressContext);
  if (!context) {
    throw new Error('usePlayerProgress must be used within a PlayerProvider');
  }
  return context;
}

export function PlayerProvider({ children }: { children: React.ReactNode }) {
  const dispatch: Dispatch = useDispatch();
  const player_ctx = useContext(MachineContext);
  const onSeek = useCallback(
    (time: number, cb: (progress: number) => void) => {
      // eslint-disable-next-line react/destructuring-assignment
      player_ctx.player.send({
        type: 'SOUGHT',
        payload: {
          time,
          cb,
        },
      });
    },
    // eslint-disable-next-line react/destructuring-assignment
    [player_ctx.player]
  );
  const playingSong = useAppSelector(playingSongSelector);
  const offlineSongs = useAppSelector((state) => state.downloader.offlineSongs);
  const player = useAppSelector((state) => state.player);

  const onSongEnded = useCallback(() => {
    dispatch(songEnded());
  }, [dispatch]);

  const onUpdatePlaybackProgress = useCallback(
    (arg: Parameters<typeof updatePlaybackProgressAction>[0]) => {
      dispatch(updatePlaybackProgressAction(arg));
    },
    [dispatch]
  );

  const offlineSong = useMemo(
    () =>
      offlineSongs && playingSong?.audioId
        ? offlineSongs[playingSong.audioId]
        : undefined,
    [offlineSongs, playingSong]
  );

  const {
    audioContextRef,
    audioRef,
    durationFormatted,
    gainNodeRef,
    handleToggleDuration,
    localDuration,
    localPlaybackProgress,
    playerVolumeRef,
    progressFormatted,
    seekTo,
    setAudioRef,
    setProgress,
    songSubtitle,
  } = usePlayer(
    playingSong,
    offlineSong,
    onSongEnded,
    player.isMuted,
    player.volume,
    onUpdatePlaybackProgress,
    onSeek
  );

  return (
    <PlayerControllerContext.Provider
      value={{
        audioContextRef,
        audioRef,
        gainNodeRef,
        handleToggleDuration,
        playerVolumeRef,
        seekTo,
        setAudioRef,
        setProgress,
        songSubtitle,
      }}
    >
      <PlayerProgressContext.Provider
        value={{
          durationFormatted,
          localDuration,
          localPlaybackProgress,
          progressFormatted,
        }}
      >
        {children}
      </PlayerProgressContext.Provider>
    </PlayerControllerContext.Provider>
  );
}
