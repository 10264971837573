export function euclideanModulo(x: number, y: number): number {
  if (y === 0) return 0;
  const yy = Math.abs(y);
  return ((x % yy) + yy) % yy;
}

export function clamp(min: number, max: number, number: number): number {
  return Math.max(min, Math.min(number, max));
}

/**
 * @example
 * lerp(0, 10, 0.5) // 5
 */
export function lerp(from: number, to: number, alpha: number): number {
  return from + alpha * (to - from);
}

/**
 * @example
 * normalize(5, 10, 0) // 0.5
 */
export function normalize(val: number, max: number, min: number): number {
  return (val - min) / (max - min);
}

export function randomIn(val: number): number {
  return Math.floor(Math.random() * val);
}

export function between(first: number, last: number, value: number): boolean {
  const lower = Math.min(first, last);
  const upper = Math.max(first, last);
  return value >= lower && value <= upper;
}
