import { z } from 'zod';
import { AttachmentIdSchema, SongIdSchema } from './Opaques';

export const PresignedRequestSchema = z.object({
  url: z.string(),
  fields: z.record(z.string()),
});

export type PresignedRequest = z.infer<typeof PresignedRequestSchema>;

export const PrepareUploadResponseSchema = z.object({
  audio_id: SongIdSchema,
  presigned_request: PresignedRequestSchema,
});

export type PrepareUploadResponse = z.infer<typeof PrepareUploadResponseSchema>;

export const PrepareAttachmentResponseSchema = z.object({
  attachment_id: AttachmentIdSchema,
  presigned_request: PresignedRequestSchema,
});

export type PrepareAttachmentResponse = z.infer<
  typeof PrepareAttachmentResponseSchema
>;
