import { z } from 'zod';
import type { UserId } from './Opaques';
import { UserIdSchema } from './Opaques';
import {
  fromNullish,
  transformToDate,
  transformToNull,
  transformTrim,
} from './zod-utils';

export const CollaboratorSchema = z
  .object({
    artist_name: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(fromNullish('')),
    avatar_url: z.string().nullish().transform(transformToNull),
    created_at: z.string().transform(transformToDate),
    email: z.string(),
    location: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(transformToNull),
    name: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(fromNullish('')),
    occupation: z
      .string()
      .transform(transformTrim)
      .nullish()
      .transform(transformToNull),
    user_id: UserIdSchema,
    // artwork_url: z.string().nullable(),
  })
  .transform((prev) => ({
    artistName: prev.artist_name,
    // artworkUrl: prev.artwork_url,
    avatarUrl: prev.avatar_url,
    createdAt: prev.created_at,
    email: prev.email,
    // isWorkspace_owner: prev.is_workspace_owner,
    location: prev.location,
    name: prev.name,
    occupation: prev.occupation,
    // stepsComplete: prev.steps_complete,
    userId: prev.user_id,
  }));

export type Collaborator = z.infer<typeof CollaboratorSchema>;

export const defaultCollaborator: Collaborator = {
  artistName: 'Taio Cruz',
  avatarUrl: 'https://i.pravatar.cc/150?img=2',
  createdAt: new Date('2021-01-01T00:00:00Z'),
  email: 'collaborator@vollume.com',
  location: null,
  name: 'Jacob',
  occupation: null,
  userId: '1' as UserId,
  // artworkUrl: null,
};
