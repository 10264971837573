import { z } from 'zod';

export const DeliveryStateSchema = z.object({
  api_base: z.string(),
  records: z.array(
    z.object({
      latest_delivered_at: z.number(),
      latest_attempted_at: z.number(),
      currently_logged_device: z.boolean(),
    })
  ),
  size: z.number(),
});

export type DeliveryState = z.infer<typeof DeliveryStateSchema>;

export const StoredDeliveryStateSchema = z.coerce.number().nullish();

export const DELIVERY_STATE_STORE_KEY = 'deliveryState';
